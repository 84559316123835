<template>
  <layout-default>
    <v-layout wrap align-center justify-center>
      <v-flex xs12 md6>
        <form>
          <v-card style="max-width: 800px">
            <v-card-title
                class="headline font-weight-regular blue-grey white--text"
            >Change password
            </v-card-title>
            <v-card-text>
              <v-text-field
                  v-model="oldPassword"
                  id="oldPassword"
                  prepend-icon="fa-key"
                  name="oldPassword"
                  label="Old password"
                  type="password"
              ></v-text-field>
              <v-text-field
                  v-model="newPassword"
                  id="newPassword"
                  prepend-icon="fa-key"
                  name="newPassword"
                  label="New password"
                  type="password"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="info" :loading="loading" @click="changePassword">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </form>
        <v-alert v-if="lastError" :value="true" color="error" icon="fa-error">{{
            lastError
          }}</v-alert>
      </v-flex>
    </v-layout>
  </layout-default>
</template>

<script>
import LayoutDefault from "@/layouts/Default.vue";
import CHANGE_PASSWORD_MUTATION from "@/graphql/mutation/ChangePasswordMutation.gql";

export default {
  data: () => {
    return {
      oldPassword: null,
      newPassword: null,
      password: null,
      lastError: null,
      hasErrors: false,
      loading: false,
    };
  },
  components: {
    LayoutDefault,
  },
  methods: {
    async changePassword() {
      const variables = {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword,
      };
      this.loading = true;

      this.$apollo
          .mutate({
            mutation: CHANGE_PASSWORD_MUTATION,
            variables,
            update: async (store, { errors }) => {
              this.loading = false;
              if (errors) {
                return;
              }
              await this.$router.push({ name: "usersList" });
            },
          })
          .catch((error) => {
            this.hasErrors = true;
            this.loading = false;
            this.lastError = error;
          });
    },
  },
};
</script>
<style lang="stylus" scoped></style>
